<script setup>
import useGetCode from '@/assets/hooks/useGetCode'
import Http from "../assets/api/index.js";


const switchLocalePath = useSwitchLocalePath()
const isregion = ref(false);
const region = ref('86'), mobile = ref(''), captcha = ref('')
const isloading = ref(false), login_loading = ref(false)
const { smsBtnText, resume, isActive } = useGetCode(60)
const formRef = ref();
const locale = ref(useI18n().locale.value)

const isEn = computed(() => {
  if (locale.value == 'en') {
    return true
  } else {
    return false
  }

})
const formState = reactive({
  title: '',
  name: '',
  phone: "",
  message: "",
  region: 'CN',
  product: [],
  captcha: '',
  domain: 'www.forthright.com.hk',
  type: 14
})
const rules = {
}
const labelCol = {
  span: 3,
};
const activeKey = ref('1')
function hadleChange(item) {
  formState.region = item.code
  region.value = item.no
  isregion.value = false
}
function regionFn() {
  isregion.value = !isregion.value
}
const indexImg = (value) => getImgURL('index', value)
async function send_sms() {
  if (!formState.phone) return
  if (isloading.value || isActive.value) return
  isloading.value = true
  const data = await sendSms({
    region: formState.region,
    mobile: formState.phone
  })
  if (data.status == 0) {
    resume()
  } else {
    console.log(data.info)
  }
  isloading.value = false
}
const lang = useI18n().locale.value;

async function holdingContact() {
  if (!formState.phone || !formState.captcha) return
  if (login_loading.value) return
  login_loading.value = true
  const data = await holdingContactUs(Object.assign({ ...formState }, { product: formState.product.join() }))
  if (data.status == 0) {
    message.success('提交成功');
  } else {
    console.log(data.info)
  }
  login_loading.value = false
}
const isIp = ref(true)
const getIp = async ()  => {
  const data =  await getIP({})
  console.log(data.data.Country)
  isIp.value = data.data.Country === '中国' ? true : false
}
  getIp()
const isPC = ref(true)
onMounted(() => {
  isPC.value = useDeviceType().value=='pc'
})
console.log(isPC.value)

</script>

<template>
  <div>
    <div>
      <div  flex w-full bg-cover h-550 lt-xl:h-319 bg-center :style="{ backgroundImage: 'url(' + (isPC? index.cn.banner:index.cn.m1_banner) + ')' }">
        <div flex justify-center mt-193 lt-xl:(mt-27)>
          <div ml-100 lt-xl:(ml-26)>
            <div class="invest-content" text-40 lt-xl:(text-20) >{{ $t('fdzytz') }}</div>
            <div mt-37 class="invest-introduce" w="60%" lt-xl:w="40%" text-22 line-height-36 lt-xl:(text-11 line-height-22 mt-20)>
              {{ $t('fdgxcihtz') }}
            </div>
          </div>
        </div>
        <div mt-193 ml--100 lt-xl:(mt-120 ml--240)>
          <div class="vertical-text" >
            <div ml-8 class="vertical-name" text-26 line-height-28 lt-xl:(text-13 line-height-28)>{{ $t('namehzw') }}</div>
            <div class="vertical-title" text-18 line-height-20 lt-xl:(text-12 line-height-10)>{{ $t('fdzksxceo') }}</div>
          </div>
        </div>
      </div>
      <!-- </a-carousel> -->
      <div>
        <div w-full bg-cover h-full bg-center bg-no-repeat lt-xl:(h-410) :style="{ backgroundImage: 'url(' + index.cn.banner1 + ')' }"
          overflow-auto>
          <div class="licensed-broker" h-50 text-36 line-height-50 mt-104 lt-xl:(text-36 mt-50 line-height-50)>{{ $t('licensed_broker') }}</div>
          <div class="licensed-broker-content" h-20 text-16 line-height-20 mt-16 lt-xl:(text-16  line-height-20)>{{ $t('licensed_broker_content')
            }}</div>
          <div class="introduce-warp" mt-20>
            <div w-282 bg-cover h-199 lt-xl:(w-171 h-119) bg-center
              :style="{ backgroundImage: 'url(' + index.cn.banner2 + ')' }">
              <div mt-50 lt-xl:(mt-15)>
                <div text-center lt-xl:(text-14)>{{ $t('introduce_category1') }}</div>
                <div text-center lt-xl:(text-12)>{{ $t('category1_content1') }}</div>
              </div>
            </div>
            <div w-282 bg-cover h-199 lt-xl:(w-171 h-119) bg-center
              :style="{ backgroundImage: 'url(' + index.cn.banner2 + ')' }">
              <div mt-50 lt-xl:(mt-15)>
                <div text-center lt-xl:(text-14)>{{ $t('introduce_category2') }}</div>
                <div text-center lt-xl:(text-12)>{{ $t('category1_content2') }}</div>
              </div>
            </div>
            <div w-282 bg-cover h-199 lt-xl:(w-171 h-119) bg-center
              :style="{ backgroundImage: 'url(' + index.cn.banner2 + ')' }">
              <div mt-50 lt-xl:(mt-15)>
                <div text-center lt-xl:(text-14)>{{ $t('d4l') }}</div>
                <div text-center lt-xl:(text-12)>{{ $t('category1_content3') }}</div>
              </div>
            </div>
            <div w-282 bg-cover h-199 lt-xl:(w-171 h-119) bg-center
              :style="{ backgroundImage: 'url(' + index.cn.banner2 + ')' }">
              <div mt-50 lt-xl:(mt-15)>
                <div text-center lt-xl:(text-14)>{{ $t('d5l') }}</div>
                <div text-center lt-xl:(text-12)>{{ $t('category1_content4') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div w-full flex justify-center>
          <div class="certified-team" w-384 mt-60 mb-20 text-center  h-full lt-xl:(h-full w-179 text-24) bg-center bg-no-repeat
            :style="{ backgroundImage: 'url(' + index.cn.banner3 + ')' }" overflow-auto font-size-48>
            {{ $t('cztd') }}
          </div>
        </div>
        <div flex justify-center gap-24 lt-xl:(block)>
          <div w-598 lt-xl:(w-340 bg-contain ml-20) bg-cover bg-no-repeat overflow-auto
            :style="{ backgroundImage: 'url(' + index.cn.banner4 + ')'}" >
            <div flex justify-between mt-18 pl-16 pr-16 lt-xl:(mt-9 pl-8 pr-8)>
              <div flex lt-xl:(text-12)>
                <div>  {{ $t('zwg') }}</div>
                <div ml-14 lt-xl:(ml-14)>CHIM Wai Kwok</div>
              </div>
              <div lt-xl:(text-12)>{{ $t('zybh') }}：ANX997</div>
            </div>
            <div flex mt-34 justify-center  lt-xl:(mt-9)>
              <div w-96 h-80  m-16 bg-no-repeat lt-xl:(text-12 w-49 h-40 m-16)
                :style="{ backgroundImage: 'url(' + index.cn.banner5 + ')', backgroundSize: 'cover' }" text-center flex
                justify-center flex-items-center>{{ $t('d1l') }}</div>
              <div w-96 h-80  m-16 bg-no-repeat lt-xl:(text-12 w-49 h-40 m-16)
                :style="{ backgroundImage: 'url(' + index.cn.banner5 + ')', backgroundSize: 'cover' }" text-center flex
                justify-center flex-items-center>{{ $t('d2l') }}</div>
              <div w-96 h-80  m-16 bg-no-repeat lt-xl:(text-12 w-49 h-40 m-16)
                :style="{ backgroundImage: 'url(' + index.cn.banner5 + ')', backgroundSize: 'cover' }" text-center flex
                justify-center flex-items-center>{{ $t('d4l') }}</div>
              <div w-96 h-80  m-16 bg-no-repeat lt-xl:(text-12 w-49 h-40 m-16)
                :style="{ backgroundImage: 'url(' + index.cn.banner5 + ')', backgroundSize: 'cover' }" text-center flex
                justify-center flex-items-center>{{ $t('d5l') }}</div>

            </div>
          </div>
          <div w-598 lt-xl:(w-340 bg-contain ml-20 mt-24) bg-cover bg-no-repeat overflow-auto
            :style="{ backgroundImage: 'url(' + index.cn.banner4 + ')' }">
            <div flex justify-between mt-18 pl-16 pr-16 lt-xl:(mt-9 pl-8 pr-8)>
              <div flex lt-xl:(text-12)>
                <div>{{ $t('fzc') }}</div>
                <div ml-14 lt-xl:(ml-14)>FAN Chun Chiu</div>
              </div>
              <div lt-xl:(text-12)>{{ $t('zybh') }}：AXN054</div>
            </div>
            <div flex mt-34 justify-center lt-xl:(mt-9)>
              <div w-96 h-80 lt-xl:(text-12 w-49 h-40 m-16) m-16 bg-no-repeat
                :style="{ backgroundImage: 'url(' + index.cn.banner5 + ')', backgroundSize: 'cover'}" text-center flex
                justify-center flex-items-center>{{ $t('d1l') }}</div>
              <div w-96 h-80 lt-xl:(text-12 w-49 h-40 m-16) m-16 bg-no-repeat
                :style="{ backgroundImage: 'url(' + index.cn.banner5 + ')', backgroundSize: 'cover' }" text-center flex
                justify-center flex-items-center>{{ $t('d2l') }}</div>
              <div w-96 h-80 lt-xl:(text-12 w-49 h-40 m-16) m-16 bg-no-repeat
                :style="{ backgroundImage: 'url(' + index.cn.banner5 + ')', backgroundSize: 'cover' }" text-center flex
                justify-center flex-items-center>{{ $t('d4l') }}</div>
              <div w-96 h-80 lt-xl:(text-12 w-49 h-40 m-16) m-16 bg-no-repeat
                :style="{ backgroundImage: 'url(' + index.cn.banner5 + ')', backgroundSize: 'cover' }" text-center flex
                justify-center flex-items-center>{{ $t('d5l') }}</div>
            </div>
          </div>
        </div>
        <div flex justify-center gap-24 mt-16 lt-xl:(block)>
          <div w-598 lt-xl:(w-340 bg-contain ml-20 mt-24) bg-cover bg-no-repeat overflow-auto
            :style="{ backgroundImage: 'url(' + index.cn.banner4 + ')' }">
            <div flex justify-between mt-18 pl-16 pr-16 lt-xl:(mt-9 pl-8 pr-8)>
              <div flex lt-xl:(text-12)>
                <div>{{ $t('jjw') }}</div>
                <div ml-14>JIANG Jiwei</div>
              </div>
              <div lt-xl:(text-12)>{{ $t('zybh') }}：AQY202</div>
            </div>
            <div flex mt-34 justify-center lt-xl:(mt-9)>
              <div w-96 h-80 lt-xl:(text-12 w-49 h-40 m-16) m-16 bg-no-repeat
                :style="{ backgroundImage: 'url(' + index.cn.banner5 + ')', backgroundSize: 'cover' }" text-center flex
                justify-center flex-items-center>{{ $t('d1l') }}</div>
              <div w-96 h-80 lt-xl:(text-12 w-49 h-40 m-16) m-16 bg-no-repeat
                :style="{ backgroundImage: 'url(' + index.cn.banner5 + ')', backgroundSize: 'cover' }" text-center flex
                justify-center flex-items-center>{{ $t('d2l') }}</div>
              <div w-96 h-80 lt-xl:(text-12 w-49 h-40 m-16) m-16 bg-no-repeat
                :style="{ backgroundImage: 'url(' + index.cn.banner5 + ')', backgroundSize: 'cover' }" text-center flex
                justify-center flex-items-center>{{ $t('d4l') }}</div>
              <div w-96 h-80 lt-xl:(text-12 w-49 h-40 m-16) m-16 bg-no-repeat
                :style="{ backgroundImage: 'url(' + index.cn.banner5 + ')', backgroundSize: 'cover' }" text-center flex
                justify-center flex-items-center>{{ $t('d5l') }}</div>

            </div>
          </div>
          <div w-598 lt-xl:(w-340 bg-contain ml-20 mt-24) bg-cover bg-no-repeat overflow-auto
            :style="{ backgroundImage: 'url(' + index.cn.banner4 + ')' }" >
            <div flex justify-between mt-18 pl-16 pr-16 lt-xl:(mt-9 pl-8 pr-8)>
              <div flex lt-xl:(text-12)>
                <div>{{ $t('xrj') }}</div>
                <div ml-14>TSE Yat Kei Teddy</div>
              </div>
              <div lt-xl:(text-12)>{{ $t('zybh') }}：AOG833</div>
            </div>
            <div flex mt-34 justify-center lt-xl:(mt-9)>
              <div w-96 h-80 lt-xl:(text-12 w-49 h-40 m-16) m-16 bg-no-repeat
                :style="{ backgroundImage: 'url(' + index.cn.banner5 + ')', backgroundSize: 'cover' }" text-center flex
                justify-center flex-items-center>{{ $t('d1l') }}</div>
              <div w-96 h-80 lt-xl:(text-12 w-49 h-40 m-16) m-16 bg-no-repeat
                :style="{ backgroundImage: 'url(' + index.cn.banner5 + ')', backgroundSize: 'cover' }" text-center flex
                justify-center flex-items-center>{{ $t('d4l') }}</div>
              <!-- <div w-96 h-80 lt-xl:( w-96) m-16 bg-no-repeat
                :style="{ backgroundImage: 'url(' + index.cn.banner5 + ')', backgroundSize: 'cover' }" text-center flex
                justify-center flex-items-center>第一类</div>
              <div w-96 h-80 lt-xl:( w-96) m-16 bg-no-repeat
                :style="{ backgroundImage: 'url(' + index.cn.banner5 + ')', backgroundSize: 'cover' }" text-center flex
                justify-center flex-items-center>第一类</div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- // 产品介绍 -->

      <div pyx>
        <div mwc>
          <div w-full flex justify-center>
            <div class="certified-team" w-384 mt-60 mb-20 text-center h-full lt-xl:(h-full w-179 text-24 mt-0) bg-center bg-no-repeat
              :style="{ backgroundImage: 'url(' + index.cn.banner3 + ')' }" overflow-auto font-size-42>
              {{  $t('cpjs') }}
            </div>
          </div>
          <ul f-c-b flex-wrap flex-row items-stretch>
            <!-- https://imgoss.techfx88.com/www_img/www_upload/default17271584035412.png -->
            <li class="group" bg-no-repeat
              :style="{ backgroundImage: 'url(' + (isPC? index.cn.banner8:index.cn.m2_banner) + ')', backgroundSize: 'cover' }" bds-palebluishgray
              px-30 w="32%" h-404 pt-60 pb-44 mb-20 relative hover:(bg-accent text-black) lt-xl:( px-15 pt-15 h-175
              mb-15) lt-xl:w='100%' cp :class="{ 'pt-30!': isEn }">
              <NuxtLink :to="localePath('/stock_trade')" external>
                <p titc l-n mb-25 lt-xl:mb-15 :class="{ 'xl:text-20': isEn }">{{ $t('zqjy') }}</p>
                <p titm lt-xl:mb-10 lt-xl:w="70%" :class="{ 'xl:text-16': isEn }">{{ $t('zqjyjs') }}</p>
                <div f-c-b absolute bottom-49 right-0 w-full px-30 lt-xl:(px-15 bottom-15 absolute)>
                  <p text-accent titm group-hover:text-paleyellowish>{{ $t('learn_more') }}</p>
                  <!-- <img lt-xl:h-30 :src="index.cn.block4_1" alt="" srcset=""> -->
                </div>
              </NuxtLink>
            </li>
            <li class="group" bg-no-repeat
              :style="{ backgroundImage: 'url(' + (isPC? index.cn.banner6:index.cn.m3_banner) + ')', backgroundSize: 'cover' }" bds-palebluishgray
              px-30 w="32%" h-404 pt-60 pb-44 mb-20 relative hover:(bg-accent text-black) lt-xl:(px-15 pt-15 h-175
              mb-15) lt-xl:w='100%' cp :class="{ 'pt-30!': isEn }">
              <NuxtLink :to="localePath('/futures_trade')" external>
                <p titc l-n mb-25 lt-xl:mb-15 :class="{ 'xl:text-20': isEn }">{{ $t('qhjy') }}</p>
                <p titm lt-xl:mb-10 lt-xl:w="70%" :class="{ 'xl:text-16': isEn }">{{ $t('qhjyjs') }}</p>
                <div f-c-b absolute bottom-49 right-0 w-full px-30 lt-xl:(px-15 bottom-15 absolute)>
                  <p text-accent titm group-hover:text-paleyellowish>{{ $t('learn_more') }}</p>
                  <!-- <img lt-xl:h-30 :src="index.cn.block4_2" alt="" srcset=""> -->
                </div>
              </NuxtLink>
            </li>
            <li class="group" bg-no-repeat
              :style="{ backgroundImage: 'url(' + (isPC? index.cn.banner7:index.cn.m4_banner) + ')', backgroundSize: 'cover' }" bds-palebluishgray
              px-30 w="32%" h-404 pt-60 pb-44 mb-20 relative hover:(bg-accent text-black) lt-xl:(px-15 pt-15 h-175
              mb-15) lt-xl:w='100%' cp :class="{ 'pt-30!': isEn }">
              <NuxtLink :to="localePath('/fund')" external>
                <p titc l-n mb-25 lt-xl:mb-15 :class="{ 'xl:text-20': isEn }">{{ $t('jjjy') }}</p>
                <p titm lt-xl:mb-10 lt-xl:w="71%" :class="{ 'xl:text-16': isEn }">{{ $t('jjjyjs') }}</p>
                <div f-c-b absolute bottom-49 right-0 w-full px-30 lt-xl:(px-15 bottom-15 absolute)>
                  <p text-accent titm group-hover:text-paleyellowish>{{ $t('learn_more') }}</p>
                  <!-- <img lt-xl:h-30 :src="index.cn.block4_3" alt="" srcset=""> -->
                </div>
              </NuxtLink>
            </li>

          </ul>
        </div>
      </div>

      <!-- //财富服务 -->
      <div >
        <div mwc>
          <div w-full flex justify-center>
            <div class="certified-team" w-384 mt-0 mb-20 text-center h-full lt-xl:(h-full w-179 text-24) bg-center bg-no-repeat
              :style="{ backgroundImage: 'url(' + index.cn.banner3 + ')' }" overflow-auto font-size-42>
              {{ $t('cffw') }}
            </div>
          </div>
          <ul f-c-b flex-wrap flex-row items-stretch justify-around mb-20>
            <li class="group" bg-no-repeat
              :style="{ backgroundImage: 'url(' + (isPC? index.cn.banner13:index.cn.m7_banner) + ')', backgroundSize: 'cover' }" bds-palebluishgray
              px-30 w="100%" h-419 pt-60 pb-44 mb-20 relative hover:(bg-accent text-black) lt-xl:(px-15 pt-15 h-175
              mb-15) lt-xl:w='100%' cp :class="{ 'pt-30!': isEn }">
              <NuxtLink :to="localePath('/wealth_planning')" external>
                <p titc l-n mb-25 lt-xl:mb-15 :class="{ 'xl:text-20': isEn }">{{ $t('private_wealth') }}</p>
                <p titm lt-xl:mb-10 w="70%"  :class="{ 'xl:text-16': isEn }">
                  {{ $t('srcfjs') }}</p>
                <div f-c-b absolute bottom-49 right-0 w-full px-30 lt-xl:(px-15 bottom-15 absolute)>
                  <p text-accent titm group-hover:text-paleyellowish>{{ $t('learn_more') }}</p>
                  <!-- <img lt-xl:h-30 :src="index.cn.block4_1" alt="" srcset=""> -->
                </div>
              </NuxtLink>
            </li>

          </ul>
          <div w-full flex justify-center>
            <div class="certified-team" w-384 mt-0 mb-20 text-center h-full lt-xl:(h-full w-179 text-24) bg-center bg-no-repeat
              :style="{ backgroundImage: 'url(' + index.cn.banner3 + ')' }" overflow-auto font-size-42>
              {{ $t('partners') }}
            </div>
          </div>
          <ul f-c-b flex-wrap flex-row items-stretch justify-around>

            <li class="group" bg-no-repeat
              :style="{ backgroundImage: 'url(' + (isPC? index.cn.banner14:index.cn.m8_banner) + ')', backgroundSize: 'cover' }" bds-palebluishgray
              px-30 w="100%" h-419 w- pt-60 pb-44 mb-20 relative hover:(bg-accent text-black) lt-xl:(px-15 pt-15 h-175
              mb-15) lt-xl:w='100%' cp :class="{ 'pt-30!': isEn }">
              <NuxtLink :to="localePath('/global_investment')" external>
                <p titc l-n mb-25 lt-xl:mb-15 :class="{ 'xl:text-20': isEn }">  {{ $t('hqtz') }}</p>
                <p titm lt-xl:mb-10 w="70%"  :class="{ 'xl:text-16': isEn }">
                  {{ $t('hqtzjs') }}
                </p>
                <div f-c-b absolute bottom-49 right-0 w-full px-30 lt-xl:(px-15 bottom-15 absolute)>
                  <p text-accent titm group-hover:text-paleyellowish>{{ $t('learn_more') }}</p>
                  <!-- <img lt-xl:h-30 :src="index.cn.block4_2" alt="" srcset=""> -->
                </div>
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>

      <!-- // 方的证券是客户的资金安全 -->

      <div pyx>
        <div mwc>
          <div w-full mb-16>
            <div class="fund-security" font-size-36 line-height-50 lt-xl:( text-24)>{{ $t('fdzqkhzj') }}</div>
            <div class="fund-security" font-size-36 line-height-50 lt-xl:(text-24)>{{ $t('qfwhh') }}</div>
          </div>
          <ul f-c-b flex-wrap flex-row >
            <li flex justify-center flex-items-center bg-no-repeat h-354 bg-cover
              :style="{ backgroundImage: 'url(' + index.cn.banner11 + ')'}" w="33%" mb-20
              relative lt-xl:(h-300 bg-contain pb-0) lt-xl:w='100%' cp :class="{ 'pt-30!': isEn }">
              <NuxtLink  external>
                <div mt-80 lt-xl:(mt-20) text-center>
                  <p text-center titc l-n mb-25 lt-xl:mb-15 :class="{ 'xl:text-20': isEn }">{{ $t('scjg') }}</p>
                  <p titm lt-xl:mb-10 :class="{ 'xl:text-16': isEn }">{{ $t('scjgja') }}</p>
                  <!-- <p titm lt-xl:mb-10 :class="{ 'xl:text-16': isEn }">{{ $t('scjg') }}</p> -->
                </div>

              </NuxtLink>
            </li>
            <li flex justify-center flex-items-center bg-no-repeat h-354 bg-cover
              :style="{ backgroundImage: 'url(' + index.cn.banner10 + ')'}" px-30 w="33%"
              pt-60 pb-44 mb-20 relative lt-xl:(h-300 bg-contain) lt-xl:w='100%' cp :class="{ 'pt-30!': isEn }">
              <NuxtLink  external>
                <div mt-80 lt-xl:(mt-20) text-center>
                  <p text-center titc l-n mb-25 lt-xl:mb-15 :class="{ 'xl:text-20': isEn }">{{ $t('qsjrzz') }}</p>
                  <p titm lt-xl:mb-10 :class="{ 'xl:text-16': isEn }">{{ $t('qsjrzzjs') }}</p>
                  <!-- <p titm lt-xl:mb-10 :class="{ 'xl:text-16': isEn }">客户资金独立托管于银行</p> -->
                </div>

              </NuxtLink>
            </li>
            <li bg-no-repeat flex justify-center  flex-items-center  h-354 :style="{ backgroundImage: 'url(' + index.cn.banner9 + ')' }" bg-cover
              px-30 w="33%" pt-60 pb-44 mb-20 relative lt-xl:(h-300 bg-contain pb-0) lt-xl:w='100%' cp
              :class="{ 'pt-30!': isEn }">
              <NuxtLink  external>
                <div mt-80 lt-xl:(mt-20) text-center>
                  <p text-center titc l-n mb-25 lt-xl:mb-15 :class="{ 'xl:text-20': isEn }">{{ $t('ytzly') }}</p>
                  <p titm lt-xl:mb-10 :class="{ 'xl:text-16': isEn }">{{ $t('ytzlyjs') }}</p>
                </div>

              </NuxtLink>
            </li>

          </ul>
        </div>
      </div>

      <!-- // 下载中心 -->
      <div v-if="!isIp" w-full bg-cover h-470 bg-center bg-no-repeat  :style="{ backgroundImage: 'url(' + (isPC? index.cn.banner12:index.cn.m9_banner) + ')' }"
        overflow-auto lt-xl:(h-500 bg-contain bg-bottom  overflow-hidden)>

        <div mwc>
          <div class="invest-globally" font-size-36 line-height-50>{{ $t('tzqqjzzj') }}</div>
          <div class="capital-account" font-size-18 line-height-32 mt-30>
            {{ $t('tzqqjzzjjs') }}<span
              class="stock-content"> {{ $t('tzqqjzzjj1') }}</span>{{ $t('tzqqjzzjj3') }}
          </div>
          <ul f-c mb-24 lt-xl:(mb-20 f-c-b mt-160 gap-4) mt-60  >
            <li mr-16 lt-xl:mr-0 >
              <NuxtLink :to="localePath('/download')" external>
                <div >
                  <button lt-xl:(w-80)  lt-xl:(text-13 pl-8 pr-8 pt-4 pb-4) pl-16 pr-16 pt-8 pb-8  class="more-detail" >{{  $t('learn_more') }}</button>

                </div>
              </NuxtLink>
            </li>
            <li mr-16 lt-xl:mr-0 >
              <a-popover :overlay-inner-style="{ padding: 0 }">
                <template #content>
                  <a-qrcode error-level="H" value="https://apps.apple.com/hk/app/sptrader-pro-hd/id1043451438"
                    :bordered="false" />
                </template>
                <div >
                  <button lt-xl:(w-90) lt-xl:(text-13 pl-8 pr-8 pt-4 pb-4) pl-16 pr-16 pt-8 pb-8 class="download-content">iPhone{{ $t('download') }}</button>
                </div>
              </a-popover>
            </li>
            <li mr-16 lt-xl:mr-0>
              <a-popover :overlay-inner-style="{ padding: 0 }">
                <template #content>
                  <a-qrcode error-level="H"
                    value="https://play.google.com/store/apps/details?id=hk.com.sharppoint.spmobile.sptraderprohd"
                    :bordered="false" />
                </template>
                <div >
                  <button lt-xl:(w-100) lt-xl:(text-13 pl-8 pr-8 pt-4 pb-4) pl-16 pr-16 pt-8 pb-8 class="download-content" >Android{{ $t('download') }}</button>
                </div>
              </a-popover>
            </li>
            <li>
              <a href="https://spsystem.biz/download/temp/EP/FRT/sptrader_forthright_setup_r87911.zip">
                <div >
                <button lt-xl:(w-65) lt-xl:(text-13 pl-8 pr-8 pt-4 pb-4) pl-16 pr-16 pt-8 pb-8 class="download-content">PC{{ $t('download') }}</button>
              </div>
              </a>
            </li>
          </ul>
        </div>


      </div>

      <div pb-30>
        <div mwc>

          <div>
            <p tit mb-40 lt-xl:mb-20>{{ $t('partners') }}</p>
            <all-company></all-company>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<style lang="less" scoped>

.licensed-broker {

  // height: 3.125rem;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  // font-size: 2.25rem;
  color: #AA7322;
  // line-height: 3.125rem;
  text-align: center;
  font-style: normal;
}

.licensed-broker-content {

  // height: 1.25rem;
  font-family: PingFangSC, PingFang SC;
  font-weight: 300;

  color: #373B50;

  text-align: center;
  font-style: normal;
}

.introduce-warp {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 2rem;
  justify-content: center;

}

.certified-team {
  background-size: 100%;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  color: #AA7322;
  font-style: normal;

}

// .licensed-introduce {
//   background-image: url('https://imgoss.techfx88.com/www_img/www_upload/default17255225348088.png');

//   background-size: 100% 100%;
//   background-repeat: no-repeat;
//   background-position: center 0;

// }
.fund-security {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  color: #504137;
  text-align: center;
  font-style: normal;

}

.invest-globally {

  font-family: PingFangSC, PingFang SC;
  font-weight: 600;

  color: #504137;


  font-style: normal;
}

.capital-account {

  font-family: PingFangSC, PingFang SC;
  font-weight: 400;

  color: #333333;

  text-align: left;
  font-style: normal;
}

.more-detail {
  border: 1px solid #AA7322;
  color: #AA7322;
  // line-height: 38px;
  background-color: #ffffff;

}

.stock-content {
  color: #AA7322;
}
.investment-but{
  border: 1px solid #DBB882;
  background: transparent;
  color: #DBB882;
}
.vertical-text {
    writing-mode: vertical-rl;

}
.vertical-title  {

font-family: PingFangSC, PingFang SC;
font-weight: 400;

color: #FFFFFF;


font-style: normal;
}
.vertical-name  {


font-family: PingFangSC, PingFang SC;
font-weight: 400;

color: #DBB882;


font-style: normal;
}
.invest-content {

font-family: PingFangSC, PingFang SC;
font-weight: 600;

color: #DBB882;
line-height: 40px;

font-style: normal;

}
.invest-introduce {
  // width: 80%;

font-family: PingFangSC, PingFang SC;
font-weight: 400;

color: #FFFFFF;

text-align: left;
font-style: normal;
}
.download-content {
  border-style: none;

    background-color: rgb(73 78 82);

    font-size: 3.5rem;

    color: rgb(255 255 255);

    outline: 2px solid transparent;
    outline-offset: 2px;
}
</style>
