<script setup>
  const lang = useI18n().locale.value
  const router = useRouter()
  const gotoDetail = (index) => {
  if (index === 0) {
    if (lang === 'cn') {
      router.push(`/wantai`)
    } else {
      router.push(`hk/wantai`)


    }
  }
}

</script>

<template>
  <div>
    <ul f-c-b flex-wrap>
        <li w-141 h-80 mb-16 lt-xl:(w-82 h-46 mb-12) v-for="(i,idx) in 16" :style=" idx === 0 ? { cursor: 'pointer' } : {}" >
          <img   :src="index.cn['block7_'+i]" alt="" srcset="" @click="gotoDetail(idx)">
        </li>
    </ul>
  </div>
</template>

